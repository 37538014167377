export const checkFile = (event: any) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 2 * 1024 * 1024; // 1 MB
    let error = null
    let file = null
    if (selectedFile && allowedTypes.includes(selectedFile.type) && selectedFile.size <= maxSize) {
        file = selectedFile
    } else {
        error = 'Please select a JPG or PNG file (up to 2MB).' 
    }
    return{file,error}
};