import landscape from "../../assets/landscape.jpg"
import portrait from "../../assets/portrait.jpg"
export const init = [
    {   
        image_id: "landscape1",
        image_url: landscape
    },
    {
        image_id: "landscape2",
        image_url: landscape
    },
    {
        image_id: "landscape3",
        image_url: landscape
    },
    {
        image_id: "portrait1",
        image_url: portrait
    },
    {
        image_id: "portrait2",
        image_url: portrait
    },
    {
        image_id: "portrait3",
        image_url: portrait
    },
    {
        image_id: "landscape4",
        image_url: landscape
    },
    {
        image_id: "landscape5",
        image_url: landscape
    },
]