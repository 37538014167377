import Sidebar from "./Sidebar";
import SmtpAdmin from "./DashboardSuper";
import { useBannerAnimation } from "../../Hooks/useBannerAnimation";
import { useGetUser } from "../../Hooks/useGetUser";
import Banner from "../../Components/Banner";
export default function Dashboard(){
   const {user} = useGetUser()
    if(user?.role === "Superadmin" || user?.role === "Admin"){
      return ( <div className="">
              <Sidebar></Sidebar>
              <div className="lg:ml-72 ml-40">
                <SmtpAdmin/>
              </div>
          </div>)
     
  }
    
    return(
   <section>
    <div className="lg:ml-72 ml-40">
    <Sidebar></Sidebar>
    </div>
    
    <div className="">
    <Banner/>
    </div>
   </section>
    )
}

