import { Outlet } from "react-router-dom";
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { HiOutlineBars3 } from "react-icons/hi2";
import logo from "../../../assets/logo.png"
import { FaXmark } from "react-icons/fa6";

export default function MaintenanceNavbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const isSticky = true;

    const handleClick = (name:any) => {
        sessionStorage.setItem('page',name)
        window.location.reload()
    };


    return (
        <>
            <header className={`w-full transition-all duration-400 ease-in-out fixed top-0 z-50`}>
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8" aria-label="Global">
                    <div className="flex md:flex-1">
                        <button className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img className="h-10 w-auto drop-shadow-lg" src={logo} alt="" loading="lazy" /> {/* Add lazy loading here */}
                        </button>
                    </div>
                    <div className={`flex md:hidden ${mobileMenuOpen ? "hidden" : ""}`}>
                  
                    </div>
                
                </nav>

            </header>
            <Outlet />
        </>
    )
}
