import { FcOvertime, FcAddressBook, FcNook, FcAddImage, FcTodoList, FcReadingEbook, FcAutomatic, FcProcess } from "react-icons/fc" 
export const components = [{
    name: "Journal",
    icon: FcOvertime,
    role: ["Superadmin", "Marketing"],
    class: 1,
    href: "/admin/journal"
 },
 {
    name: "Promo",
    icon: FcAddressBook,
    role: ["Superadmin", "Marketing"],
    class: 2,
    href: "/admin/promo"
 },
 {
    name: "Blog",
    icon: FcNook,
    role: ["Superadmin", "Marketing"],
    class: 3,
    href: "/admin/blog"
 },
 {
    name: "Gallery",
    icon: FcAddImage,
    role: ["Superadmin", "Marketing"],
    class: 5,
    href: "/admin/gallery?category=Team"
 },
 {
    name: "Referral",
    icon: FcReadingEbook,
    role: ["Superadmin", "Marketing"],
    class: 7,
    href: "/admin/referral"
 },
 {
    name: "Report",
    icon: FcTodoList,
    role: ["Superadmin"],
    class: 6,
    href: "/admin/report"
 },
 {
    name: "Register",
    icon: FcAutomatic,
    role: ["Superadmin"],
    class: 8,
    href: "/admin/register"
 },

 {
   name: "ToggleReservation",
   icon: FcProcess,
   role: ["Superadmin"],
   class: 9,
   href: "/admin/togglereservation"
},

 ]
 export const componentPortal = [{
    name: "Driver(s) Account",
    href: "/admin/portal/driver",
    role: ["Superadmin", "Admin"]
 },
  
 ]