'use client';
import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Modal, Tooltip } from 'flowbite-react';
import { IoIosJournal, IoIosLink } from "react-icons/io";
import { FcFile } from "react-icons/fc";
import NotFound from "../../Components/notFound";
import PageBanner from "../../Components/PageBanner";
import Title from "../../Components/Title";
import SpinnerLoading from "../../Components/Spinner";
import { useGetUser } from "../../Hooks/useGetUser";
import { useJournal } from "../../Hooks/useJournal";


export default function JournalAdmin() {
    const {item,setItem,editItem,handleSave,openModal,setOpenModal,message,sortedData} = useJournal()
    const {user} = useGetUser()
    if( user?.role !== "Superadmin" && user?.role !== "Marketing"){
        return ( <div className="">
                <Sidebar></Sidebar>
                <div className="lg:ml-72 ml-40">
                <NotFound/>
                </div>
            </div>)
       
    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            {!sortedData && <SpinnerLoading></SpinnerLoading>}
            <div className="lg:ml-72 ml-40">
                <section className="bg-center bg-fixed text-lagrand-white md:bg-cover journal bg-gray-400 bg-blend-multiply">
                    <PageBanner Heading="Journals" SubHeading="A place where there are a lot of memories & happy moments. La Grande Residence has its own Monthly Issue Magazines or Journals about the happenings in La Grande Residence. A very informative marketing material that contains news and updates, new promotions, and happy moments." />
                    <div className="bg-lagrand-white pt-10 pb-20 revealBottom">
                       <Title Heading="Latest Journals" SubHeading=""/>
                        <div className="md:px-[200px] xl:px-[500px]">
                        <Tooltip content="This will automatically delete the oldest Journal">
                        <button onClick={()=>{editItem(null)}} className="text-gray-900 bg-lagrand-white hover:bg-gray-200 shadow-sm border focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  me-2 mb-2">
                        <FcFile className="w-5 h-5 mr-2" />  Upload New Journal
                        </button></Tooltip>
                        </div>
                        
                        <div className="revealBottom2">
                            <div className="md:grid grid-cols-4 gap-4 px-10 md:px-[200px] xl:px-[500px]">
                                {sortedData?.map((item:any) => (
                                    <div key={item.name} onClick={() => { editItem(item) }} rel="noopener noreferrer">
                                        <img src={item.image_url} alt={item.name} loading="lazy" className="transform mx-auto transition duration-300 hover:scale-105 shadow-xl"></img>
                                        <h2 className="text-xl font-normal text-center mx-auto pt-4 text-gray-900 md:text-xl">{item.name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>{item?.name ? item.name:"New Journal"}</Modal.Header>
                <Modal.Body>   
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Journal Name</label>
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <IoIosJournal className="w-4 h-4" />
                        </div>
                        <input type="text" id="input-group-1" value={item?.name} onChange={(e) => {
                            const { value } = e.target
                            setItem({ ...item, name: value })
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Journal name"/>
                    </div>
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Journal Link</label>
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <IoIosLink className="w-4 h-4" />
                        </div>
                        <input type="text" id="input-group-1" value={item?.href} onChange={(e) => {
                            const { value } = e.target
                            setItem({ ...item, href: value })
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Journal Link"/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="focus:outline-none text-lagrand-white bg-[#416a35] hover:bg-[#2c4824] focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" onClick={() => handleSave()}>Save</button>
                    <h1 className={`text-sm ${message === "success" ?"text-lagrand-greenlight" : "text-red-700"}`}>{message}</h1>
                </Modal.Footer>
            </Modal>
        </section>
    );
}