import { useState } from "react";
import { useFetchDataPaginate } from "./useFetchDataPaginate";
import { checkFile } from "../Functions/checkFile";
import Api from "../Api/api";
interface Blog {
    id?: number,
    title?: string,
    body?: string,
    image_url?: string
}
export const useBlog = () =>{
    const [openModal, setOpenModal] = useState(false);
    const [item, setItem] = useState<Blog>()
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const {data,appendData,page,totalPage,update,setUpdate} = useFetchDataPaginate("blog")

    const editItem = (item: any) => {
        setItem(item)
        setFile(null)
        setOpenModal(true)

    }
    const handleChange = (event: any) => {
       const {file,error} = checkFile(event)
       setFile(file)
       setError(error)
    };
    const handleSave = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            Api.upload("image", formData).then((data: any) => {
                const { file_url } = data
                if (item?.image_url) {
                    const id = item.image_url.replace("https://lagranderesidence.com/uploads/", "")
                    Api.update("blog", {
                        id: item?.id,
                        title: item?.title,
                        body: item?.body,
                        image_url: file_url
                    }).then(() => {

                        setOpenModal(false)
                        setUpdate(!update)
                    })

                }
                else {
                    Api.post("blog", {
                        title: item?.title,
                        body: item?.body,
                        image_url: file_url
                    }).then((data) => {
                        setOpenModal(false)
                        setUpdate(!update)
                    }

                    )
                }

            })

            return;
        }
        if (item?.image_url) {
            Api.update("blog", item).then(() => {
                setOpenModal(false)
                setUpdate(!update)
            })
        }


    }
    const handleDelete = () => {
        if (item?.image_url) {
            const id = item.image_url.replace("https://lagranderesidence.com/uploads/", "")
            Api.delete("blog", item.id).then(() => {
                setOpenModal(false)
                setUpdate(!update)
            })
        }
        else {
            setOpenModal(false)
            setUpdate(!update)
        }
    }

    return{data,appendData,page,totalPage,handleChange,handleDelete,handleSave,editItem,file,error,setItem,item,setOpenModal,openModal}
}