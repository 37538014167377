
import { useEffect, useState } from "react"
import NotFound from "../../Components/notFound"
import { useGetUser } from "../../Hooks/useGetUser"
import Sidebar from "./Sidebar"
import Api from "../../Api/api"
interface ToggleButton{
    id?:number,
    name?:string,
    toggle?:boolean,

}

export default function ReservationOnOff() {
    const [toggle, setToggle] = useState<boolean>(false)
    const [response, setResponse] = useState<ToggleButton>({})
    const { user } = useGetUser()
    useEffect(()=>{
        Api.getall("reservation").then((response:ToggleButton)=>{
            setResponse(response)
        })
    },[toggle])
    const handletoggle = async ()=>{
        await Api.update("reservation",{
            id:1,
            toggle: !response?.toggle
        })
    }
    if (user?.role !== "Superadmin" && user?.role !== "Marketing") {
        return (<div className="">
            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <NotFound />
            </div>
        </div>)

    }
    console.log(response)
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            <div className="lg:ml-72 ml-40">
                <div className="flex h-screen place-items-center max-w-4xl mx-auto">
                    
                 
                    <div className="mx-auto w-full text-center">
                    <h1 className="text-[100px] ">Reservation :{response.toggle ?"Active":"Inactive"}</h1>
                    <label className="inline-flex items-center cursor-pointer mx-auto text-center">
                        <input type="checkbox" checked={response.toggle} onChange={() => {

                            setToggle(!toggle)
                            handletoggle()
                        }} value="" className="sr-only peer" />
                        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle Reservation</span>
                    </label>
                    </div>
                </div>
            </div>


           
        </section>
    )
}