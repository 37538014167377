import { useState, useEffect } from "react"
interface User {
    id? :number,
    username?: string,
    role?: string

}
export const useGetUser =()=>{
    const [user, setUser] = useState <User | null>()
    useEffect(() => {
     
      const user = localStorage.getItem("user")
      if(user){
          setUser(JSON.parse(user))
      }
  }, [])
  return{user}
}