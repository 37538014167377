import { useState, useEffect } from "react";
import { useGetUser } from "./useGetUser";

import Api from "../Api/api";
export const useSidebar = () => {
   const [classs, setClass] = useState([true])
   const [portal, setPortal] = useState(false)
   useEffect(() => {
      Api.islogin()
      const currentURL = window.location.href;
      let activeIndex = 0;
      switch (true) {
         case currentURL.includes("journal"):
            activeIndex = 1;
            break;
         case currentURL.includes("promo"):
            activeIndex = 2;
            break;
         case currentURL.includes("blog"):
            activeIndex = 3;
            break;
         case currentURL.includes('portal'):
            setPortal(true);
            activeIndex = 4;
            break;
         case currentURL.includes("gallery"):
            activeIndex = 5;
            break;
         case currentURL.includes("report"):
            activeIndex = 6;
            break;
         case currentURL.includes("referral"):
            activeIndex = 7;
            break;
         case currentURL.includes("register"):
            activeIndex = 8;
            break;
         default:
            break;
      }
      const list = new Array(8).fill(false);
      list[activeIndex] = true;
      setClass(list);
   }, []);

   return { classs, portal, setPortal }
}