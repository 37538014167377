import { useState } from "react"
import Api from "../Api/api"
import { Journal } from "../Interface/Journal"
import { useFetchAllData } from "./useFetchData"


export const useJournal = ()=>{
    const [openModal, setOpenModal] = useState(false)
    const [item, setItem] = useState<Journal>()
    const [message,setMessage] = useState<any>()
    const{data,setUpdate,update} = useFetchAllData("journal")
    const sortedData = [...data].sort((a, b) => b.id - a.id);
    const lastItem = sortedData[sortedData.length - 1]
    const count = 12
    if(count < sortedData.length){
        Api.delete("journal",lastItem.id).then(()=>{
            setUpdate(!update)
        })
    }
     const getImageurl = (href: string | undefined) => {
        let image_url = href
        image_url = image_url?.replace("https://heyzine.com/flip-book/", "")
        image_url = image_url?.replace(".html", ".jpg")
        return image_url
    }
    const editItem = (item: any) => {
        setItem(item)
        setOpenModal(true)

    }
    const handleSave = () => {
        const image_url = getImageurl(item?.href)
        const data: Journal = {
            name: item?.name,
            href: item?.href,
            image_url: `https://cdnc.heyzine.com/flip-book/cover/${image_url}`}
        if (item?.id) {
            data['id'] = item?.id
          Api.update("journal",data).then((data:any)=>{
            window.location.reload()
          })
          return
        }
           Api.post("journal", data).then((data:any)=>{
            const {message} = data
           if (message === "success"){
            window.location.reload()
           }
           setMessage(message)
           }) 
           return
    
}
    return {getImageurl,item,setItem,editItem,handleSave,openModal,setOpenModal,message,sortedData}
}