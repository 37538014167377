
"use client";
import { useEffect, useState } from "react";
import fam from "../../../assets/FamilyGreen.png"
import { Button, Modal, Spinner } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import logo from "../../../assets/logo.png"
import QRCode from "react-qr-code";
import RedeemReferral from "./RedeemReferral";
import Api from "../../../Api/api";
export default function Referral() {
    const [formdata, setFormdata] = useState<any>({})
    const [employee,setEmployee] = useState<any>({RemainingBalance: 0})
    const [reward,setReward] = useState<any>([])
    const [redeem,setRedeem] = useState<any>({})
    const [code, setCode] = useState<string>()
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const currentUrl = window.location.href
    const [redeemData, setRedeemData] = useState<any>()
    useEffect(()=>{
        const searchParams = new URLSearchParams(window.location.search);
        const param =  searchParams.get('code')
        const range = "Referral System"
        Api.post("spreadsheet/all",{
            "id": "1wtuZ21k16fWZOSjqy8iQf-UnSfggHpvUW8BA0BbHUi8",
            "range": `${range}!D:E`,
      
        }).then((response)=>{
            const {data} = response
            
            setReward(data)
        })
        const redeemReward = async () =>{
            await Api.getbyid("spreadsheet",param).then((response:any)=>{
                if(response){
                const{data} = response
                setRedeemData(data)
                }
                else{
                    alert("Redeem Code not Found/Expired")
                    window.location.replace("/referral")
                }
            })
        }
        Api.getall("spreadsheet")
        if (param){
            redeemReward()
        }
        
    },[])
    const onRedeem = (points:any) =>{
        setRedeem(points)
        setOpenModal(true)
        
    }
    const generateCode = () =>{
        setLoading(true)
        Api.post("spreadsheet/generate",{
            employeeName : `${employee.EmployeeName}`,
            amount : `${redeem.Points}`,
             details : `${redeem.Rewards}`
         }).then((response)=>{
            const {data} = response
            if(data){
                setCode(data)
            }
            setLoading(false)
         })
         
    }
    const onSearch = () => {
        let range = "Referral Summary"
        Api.post("spreadsheet/name", {
            "id": "1wtuZ21k16fWZOSjqy8iQf-UnSfggHpvUW8BA0BbHUi8",
            "range": `${range}!A:D`,
            "name": formdata.Name.toUpperCase()
        }).then((response: any) => {
            const {data} = response
            if(data){
                setEmployee(data) 
            }
            else{
                setEmployee({RemainingBalance: 0})
            }
        })
    }
    if(redeemData){
        return(
            <RedeemReferral code={redeemData}/>
        )
    }
    return (
        <section className={`bg-center bg-fixed  text-lagrand-white  md:bg-cover landing bg-gray-400 bg-blend-multiply`}>
    
            <div className="lg:px-[200px]  h-screen  pt-5 md:px-[100px] xl:px-[500px]  px-5">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    onSearch();
                }} >
    
                    <div className=" ">

                        <div className="max-w-xl mx-auto p-6 bg-white  rounded-t-lg  ">


                            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input value={formdata.Name} onChange={(e) => {
                                    const { value } = e.target;
                                    setFormdata({ ...formdata, Name: value }); // If value is undefined, set it to an empty string
                                }} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Employee Name" required />
                                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-lagrand-greenlight hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Search</button>
                            </div>

                        </div>
                    </div>
                </form>


                <div className="w-full mx-auto max-w-xl p-4 bg-white  rounded-b-lg sm:p-8  ">
                    <div className="flex items-center justify-between mb-4">
                        <h5 className="text-xl font-bold leading-none text-gray-900 ">Employee Referral Details</h5>
                    
                    </div>
                    <div className="flow-root ">
                        <ul role="list" className="divide-y divide-gray-200 ">
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img className=" w-12 h-7 rounded-full" src={fam} alt="La grande Family" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate ">
                                            {employee?.EmployeeName}
                                        </p>
                                       
                                    </div>
                                    <div className=" items-center text-base font-semibold text-gray-900 ">
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Total Points
                                        </p>
                                        {employee?.RemainingBalance}
                                        
                                    </div>
                                </div>
                            </li>

                        </ul>
                        <hr></hr>
                        {reward?.map((item:any)=>(
                            <ul role="list" className="divide-y border-t divide-gray-200 ">
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center">
                                
                                    <div className="flex-1 min-w-0">
                                        <p className="text-xs font-medium text-gray-900 truncate ">
                                            {item.Rewards}
                                        </p>
                                        <p className="text-xs font-medium text-gray-600 truncate ">
                                            {item.Points} points
                                        </p>
                                       
                                    </div>
                                    <div className=" items-center text-base font-semibold text-gray-900 ">
                                    <button onClick={()=>onRedeem(item)} disabled={parseInt(item.Points) > parseInt(employee?.RemainingBalance)} className="text-white end-2.5 bottom-2.5 bg-lagrand-greenlight disabled:bg-gray-300 hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Redeem</button>
                            
                                    </div>
                                </div>
                            </li>

                        </ul>
                        
                        ))}
                    </div>
                </div>

            </div>
            <Modal show={openModal} size="md" onClose={() => {setOpenModal(false) 
                setCode("")}} popup>
        <Modal.Header />
        <Modal.Body>
            {loading?  <div className=" w-full text-center ">
            <h3 className="mb-5 text-lg font-normal text-lagrand-black dark:text-gray-400">
              Generating QR Code
            </h3>
            <Spinner className="mb-5" color="success" aria-label="Success spinner example" />
            </div>:
            <>{code? 
                <div className="">
                    <img className="h-10 mx-auto mb-5" alt="la grande residence" src={logo}></img>
                    <h3 className="mb-5 text-sm font-normal text-center text-lagrand-black dark:text-gray-400">
                    Kindly present this QR Code to redeem your {redeem.Rewards}.
            </h3>
                <QRCode className="mx-auto mb-10" value={`${currentUrl}?code=${code}`}/>
                <h5 className="text-xl font-bold leading-none text-gray-900 ">Qr Code Details</h5>
                <ul role="list" className="divide-y divide-gray-200 ">
                            <li className="pb-3">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img className=" w-12 h-7 rounded-full" src={fam} alt="La grande Family" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate ">
                                            {employee?.EmployeeName}
                                        </p>
                                       
                                    </div>
                                    <div className=" items-center text-base font-semibold text-gray-900 ">
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Code
                                        </p>    
                                        {code}
                                        
                                    </div>
                                </div>
                            </li>

                        </ul>
                </div>
                :
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-sm font-normal text-lagrand-black dark:text-gray-400">
              Do you want to Redeem {redeem.Rewards}?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => generateCode()}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => {setOpenModal(false)}}>
                No, cancel
              </Button>
            </div>
          </div>}
          </>
          }
        </Modal.Body>
      </Modal>
        </section>
    );
}