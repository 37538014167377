 const time_convert = {
    time: (time: String) => {
        const [hours, minutes] = time.split(':');
        let period = 'AM';
      
        let hours12 = parseInt(hours, 10);
      
        if (hours12 >= 12) {
          period = 'PM';
          if (hours12 > 12) {
            hours12 -= 12;
          }
        }
        const formattedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
        const time12 = `${hours12}:${formattedMinutes} ${period}`;
      
        return time12;
    }
}

export default time_convert