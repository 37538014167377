import { useState } from "react";
import image from "../../../assets/logo.png"
import Api from "../../../Api/api";
export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error,setError] = useState()
    const handleSignIn = () => {
        Api.login("login",{username:username,password:password}).then((data:any)=>{
            setError(data)
            if(data === "Success"){
                window.location.replace("/admin/dashboard")
            }
        })
    }
    return (

        <section className="mx-auto max-w-xl h-screen lg:flex place-items-center ">
            <div className="lg:p-20  pt-20 lg:bg-lagrand-white lg:rounded-xl lg:shadow-xl  ">
                <form className="max-w-sm mx-10 lg:mx-auto" onSubmit={(e) => {
                    e.preventDefault();
                    handleSignIn();
                }}>
                    <div className="mb-10">
                        <img src={image} className="h-10 mx-auto" alt="la grande residence"></img>
                    </div>
                    <div className="mb-5 lg:w-80">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your username</label>
                        <input
                            onChange={(e) => setUsername(e.target.value)}
                            type="text" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  " placeholder="username" required />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type="password" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required placeholder="*****" />
                    </div>
                    <div className="flex items-start mb-5">
                       {/** <label htmlFor="terms" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> <a href="#" className="text-[#588a3c]  hover:underline hover:text-lagrand-green">Forgot Password</a></label> */}
                    </div>
                    <button type="submit" className="text-lagrand-white bg-[#588a3c] hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center">Login</button>
                    {error && <h1 className={`text-sm  text-center mt-5 underline ${error == "Success" ? " text-lagrand-greenlight": " text-red-700"}`}>{error}</h1>}
                </form>
               

            </div>
        </section>
    )
}