import { useState } from "react";
import image from "../../assets/logo.png";
import Sidebar from "./Sidebar";
import NotFound from "../../Components/notFound";
import Api from "../../Api/api";
import { useGetUser } from "../../Hooks/useGetUser";
export default function DriverAcc (){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const {user} = useGetUser()
    
   
    const handleSignIn = () => {
        Api.post("driver",{name:name,email:email}).then((data:any)=>{
            if (data.message){
                alert(data.message)
            }
           
           // window.location.reload()
        })
    }
    if( user?.role !== "Admin" && user?.role !== "Superadmin"){
        return ( <div className="">
                <Sidebar></Sidebar>
                <div className="lg:ml-72 ml-40">
                <NotFound/>
                </div>
            </div>)
       
    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            <div className="lg:ml-72 ml-40">
        <div className="mx-auto max-w-xl h-screen lg:flex place-items-center ">
            <div className="lg:p-20  pt-20 lg:bg-white lg:rounded-xl lg:shadow-xl  ">
                <form className="max-w-sm mx-10 lg:mx-auto" onSubmit={(e) => {
                    e.preventDefault();
                    handleSignIn();
                }}>
                    <div className="mb-5">
                        <img src={image} className="h-10 mx-auto" alt="la grande"></img>
                    </div>
                    <div className="mb-5 lg:w-80">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Driver's Email</label>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  " placeholder="@email.com" required />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Driver's Name</label>
                        <input
                            onChange={(e) => setName(e.target.value)}
                            type="text" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" required placeholder="Name" />
                    </div>
                    <div className="flex items-start mb-5">
                        <label htmlFor="terms" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> <a href="/portal/driver" className="text-[#588a3c]  hover:underline hover:text-lagrand-green">Driver's Portal Link</a></label>
                    </div>
                    <button type="submit" className="text-white bg-lagrand-greenlight hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center">Add Account</button>
                </form>

            </div>
        </div>
        </div>
        </section>
    )
}