import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FcOvertime, FcAddressBook, FcNook, FcAddImage, FcTodoList } from "react-icons/fc";
import { useSidebar } from "../../Hooks/useSidebar";
import { useGetUser } from "../../Hooks/useGetUser";
import { components, componentPortal } from "../../Utility/Objects/Sidebar";
export default function Sidebar() {
   const {user} = useGetUser()
   const { classs, portal,  setPortal } = useSidebar()
   return (
      <div className="bg-gray-100  text-black">
         <aside className="fixed top-0 left-0 z-40 lg:w-72 w-48 h-screen " aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 drop-shadow-lg ">
               <ul ><li >
                 
               <Link to="/admin/dashboard" className={`flex mt-3 p-3 transition-transform hover:translate-x-2  w-full ${!classs[0] ? 'bg-gray-50 text-black hover:text-black ' : 'bg-lagrand-green text-lagrand-white shadow-md hover:text-lagrand-white '}  rounded-md`}
                  >
                     <LuLayoutDashboard className={`h-5 w-5 mx-2 ${!classs[0] ? 'text-lagrand-green' : 'text-lagrand-white'}`} /> Dashboard
                  </Link>
                  {components.map((item)=>(
                     <>
                     {user?.role && item.role.includes(user?.role) &&
                    <Link key={item.name} to={item.href} className={`flex mt-3 p-3 w-full transition-transform hover:translate-x-2 ${!classs[item.class] ? 'bg-gray-50 text-black hover:text-black ' : 'bg-lagrand-green text-lagrand-white shadow-md hover:text-lagrand-white '} rounded-md`}
                    >
                          <item.icon className = "h-5 w-5 mx-2"/>{item.name}
                       </Link> 
                     }
                     </>
                  ))}
                  <button type="button" onClick={()=>{setPortal(!portal)}} className={` flex mt-3 p-3 w-full transition-transform hover:translate-x-2 ${!classs[4] ? 'bg-gray-50 text-black hover:text-black ' : 'bg-lagrand-green text-lagrand-white shadow-md hover:text-lagrand-white '} rounded-md items-center w-full p-2 text-base text-gray-900 transition duration-75 group`} aria-controls="dropdown-pages" data-collapse-toggle="dropdown-pages" aria-expanded="false">
                  <FcNook className="h-5 w-5 mx-2" />
                    <span className="flex-1 text-left whitespace-nowrap" sidebar-toggle-item="">Portals</span>
                   <svg sidebar-toggle-item="" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
               </button>
               <ul id="dropdown-pages" className={`py-2 space-y-2  ${portal ? "" : "hidden"} overflow-hidden`}>
                  {componentPortal.map((item)=>(
                      <li>
                        {user?.role && item.role.includes(user?.role) &&
                      <Link to={item.href} className="flex  items-center p-2 text-base text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700">{item.name}
                          </Link>
                          }
                      </li>
                  ))}               
               </ul>
               
              
               </li>
               <li>
            <a onClick={()=>localStorage.clear()} href="/admin/login" className="flex p-3 items-center group text-gray-900 rounded-lg dark:text-lagrand-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <RiLogoutCircleLine className="h-5 w-5 mx-2 transition-transform group-hover:translate-x-2 group-hover:text-red-500" />
               <span className="flex-1 lagrand-whitespace-nowrap transition-transform group-hover:translate-x-2">Logout</span>
            </a>
         </li>
               </ul>
               
            </div>
         </aside>

         <div />
         <div className="lg:ml-72 ml-40 ">
            <Outlet />
         </div>
      </div>

   )
}