import { useBannerAnimation } from "../Hooks/useBannerAnimation";

export default function Banner(){
    const isVisible = useBannerAnimation(3000).isVisible;
    const isVisible2 = useBannerAnimation(6000).isVisible;
    return(
        <section className="bg-center bg-fixed text-lagrand-white landing flex bg-black bg-opacity-70 place-items-center h-screen bg-blend-multiply overflow-hidden">
        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56 overflow-hidden">
            <h1 className={`mb-4 text-sm font-semibold tracking-wide leading-none text-shadow text-lagrand-white lg:text-xl ${isVisible ? 'revealBottom' : ''}`}>La Grande Residence</h1>
            <h1 className={`mb-2 text-2xl font-normal mx-auto tracking-wider leading-none max-w-4xl transition-opacity text-lagrand-white md:text-5xl lg:text-7xl ${isVisible ? 'revealBottom2' : ''}`}>{isVisible2 ? 'Your Urban Haven in' : 'Enjoy & Experience'}</h1>
            <h1 className={`mb-8 text-2xl font-normal mx-auto tracking-wider leading-none max-w-4xl transition-opacity text-lagrand-white md:text-5xl lg:text-6xl ${isVisible ? 'revealBottom3' : ''}`}>{isVisible2 ? 'Angeles City' : ' our Distinction'}</h1>
            <p className={`mb-8 text-sm font-normal text-gray-50 lg:text-xl sm:px-16 lg:px-48 ${isVisible ? 'revealBottom4 ' : ''}`}>Great place like home, great family environment, great memories to remember!</p>
            <div className=" md:space-y-4 md:flex-row md:justify-center space-y-0">

            </div>

        </div>

    </section>
    )
}