import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { FcFile } from "react-icons/fc";
import { Modal } from "flowbite-react";
import NotFound from "../../Components/notFound";
import { SlArrowDownCircle } from "react-icons/sl";
import Title from "../../Components/Title";
import PageBanner from "../../Components/PageBanner";
import { useGallery } from "../../Hooks/useGallery";
import { useGetUser } from "../../Hooks/useGetUser";

export default function GalleryAdmin() {
    const { user } = useGetUser()
    const {data,openModal,setModal,modal,loading,error,totalPage,setNewData,newData,setUpdate,category,handleChange,update,page,appendData,dataId,setFile,init,deleteData,handleUpdate,handleSave,setDataId} = useGallery()
      
    if (user?.role !== "Admin" && user?.role !== "Superadmin") {
        return (<div className="">
            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <NotFound />
            </div>
        </div>)

    }
    return (
        <>

            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <section className="bg-center bg-fixed text-lagrand-white landing bg-black bg-opacity-60 place-items-center bg-blend-multiply overflow-hidden">
                  <PageBanner Heading="Our Gallery" SubHeading=""/>

                </section>
                <div className="bg-lagrand-white pt-10">
                    <Title Heading="Image Gallery" SubHeading=""/>
                    <div className="md:px-[200px] xl:px-[500px] px-10">


                        <div className="flex items-center justify-center py-4 md:py-5 flex-wrap text-gray-600">
                            <Link type="button" onClick={() => { setUpdate(!update) }} to="?category=Team" className={` hover:text-lagrand-white border ${category === "Team" && 'border-lagrand-green text-lagrand-green'} bg-lagrand-white hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-[#508740] rounded-full text-base font-light px-5 py-2.5 text-center me-3 mb-3`}>Our Team</Link>
                            <Link type="button" onClick={() => { setUpdate(!update) }} to="?category=Events" className={` hover:text-lagrand-white border ${category === "Events" && 'border-lagrand-green text-lagrand-green'} bg-lagrand-white hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-[#508740] rounded-full text-base font-light px-5 py-2.5 text-center me-3 mb-3`}>Events</Link>
                            <Link type="button" onClick={() => { setUpdate(!update) }} to="?category=Innovation" className={` hover:text-lagrand-white border ${category === "Innovation" && 'border-lagrand-green text-lagrand-green'} bg-lagrand-white hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-[#508740] rounded-full text-base font-light px-5 py-2.5 text-center me-3 mb-3`}>Others</Link>

                        </div>
                        <div className="md:px-40">
                            <hr></hr>

                            <button onClick={openModal} className="text-gray-900 mt-2 bg-lagrand-white hover:bg-gray-200 shadow-sm border focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  me-2 mb-2">
                                <FcFile className="w-5 h-5 mr-2" />  Upload New Image Gallery ({category === "Innovation" ? "Others" : category})
                            </button>


                            {data.map((item: any) => (
                                <div onClick={() => { openModal(item) }}>
                                    <div className="grid grid-cols-3 gap-4 py-5">
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[0].image_url} alt="" loading="lazy" />
                                        </div >
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[1].image_url} alt="" loading="lazy" />
                                        </div >
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[2].image_url} alt="" loading="lazy" />
                                        </div >
                                    </div>

                                    <div className="grid grid-cols-3 gap-4 py-5">
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[3].image_url} alt="" loading="lazy" />
                                        </div >
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[4].image_url} alt="" loading="lazy" />
                                        </div >
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[5].image_url} alt="" loading="lazy" />
                                        </div >
                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div >
                                            <img className="h-auto max-w-full rounded" src={item.image_list[6].image_url} alt="" loading="lazy" />
                                        </div>
                                        <div>
                                            <img className="h-auto max-w-full rounded" src={item.image_list[7].image_url} alt="" loading="lazy" />
                                        </div>


                                    </div>
                                </div>
                            ))}
                            <div className="w-full text-center">
                                {totalPage !== page && totalPage !== 0 ? <button type="button" className="mt-5" onClick={appendData}>
                                    <SlArrowDownCircle className="h-12 w-12 text-lagrand-green  animate-bounce" />
                                </button> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onClose={() => {
                setModal(false)
                setDataId(null)
            }}>
                <Modal.Header>New Gallery {category === "Innovation" ? "Others" : category}</Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-3 gap-4 py-5">
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded " src={newData[0].image_url} alt="" />
                                </div >
                                <input id="dropzone-file" name={`${newData[0].image_id}`} type="file" disabled={newData.id} className="hidden" accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 0)} />
                            </label>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file2" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded " src={newData[1].image_url} alt="" />
                                </div >
                                <input id="dropzone-file2" name={`${newData[1].image_id}`} type="file" disabled={newData.id} className="hidden" accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 1)} />
                            </label>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file3" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded " src={newData[2].image_url} alt="" />
                                </div >
                                <input id="dropzone-file3" name={`${newData[2].image_id}`} type="file" disabled={newData.id} className="hidden" accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 2)} />
                            </label>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-4 py-5">
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file6" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded" src={newData[3].image_url} alt="" />
                                </div >
                                <input id="dropzone-file6" name={`${newData[3].image_id}`} type="file" className="hidden" disabled={newData.id} accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 3)} />
                            </label>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file7" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded" src={newData[4].image_url} alt="" />
                                </div >
                                <input id="dropzone-file7" name={`${newData[4].image_id}`} type="file" className="hidden" disabled={newData.id} accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 4)} />
                            </label>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file8" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded" src={newData[5].image_url} alt="" />
                                </div >
                                <input id="dropzone-file8" name={`${newData[5].image_id}`} type="file" className="hidden" disabled={newData.id} accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 5)} />
                            </label>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file4" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded " src={newData[6].image_url} alt="" />
                                </div >
                                <input id="dropzone-file4" name={`${newData[6].image_id}`} type="file" disabled={newData.id} className="hidden" accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 6)} />
                            </label>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file5" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100 ">
                                <div>
                                    <img className="h-auto max-w-full roundedborder rounded " src={newData[7].image_url} alt="" />
                                </div >
                                <input id="dropzone-file5" name={`${newData[7].image_id}`} type="file" className="hidden" disabled={newData.id} accept=".jpg,.jpeg,.png" onChange={(e) => handleChange(e, 7)} />
                            </label>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {dataId ? <>  <button className="focus:outline-none text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" disabled={loading} onClick={() => { handleUpdate() }}>Update</button>
                        <button className="focus:outline-none text-lagrand-white bg-red-700 hover:bg-red-800 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" onClick={() => { deleteData(newData) }}>Delete Image Group</button> </> :
                        <>  <button className="focus:outline-none text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" disabled={loading} onClick={() => { handleSave() }}>Save</button>
                            <button className="focus:outline-none text-lagrand-white bg-red-700 hover:bg-red-800 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2" onClick={() => {
                                setFile([])
                                setNewData(init)
                            }}>clear</button></>}
                    {error && <div className="text-sm underline text-red-700">{error}!</div>}
                </Modal.Footer>
            </Modal>
        </>
    )
}