import axios from 'axios';
const url = 'https://www.lagranderesidence.com/api/'

const Api = {
    getbyid: async (route: string, id: any) => {
        try {
            axios.defaults.headers.common['Cache-Control'] = 'no-cache';
            const response = await axios.get(`${url}${route}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'Access-Type-Key': 'Ld2TxLC4mziYedaiah2tUaRX3lXkQ=='
                }
            });
            return response.data;
        } catch (error: any) {
            // Handle errors
            if(localStorage.getItem('token')){
                localStorage.clear();
            window.location.replace("/admin/login");
            }
        }
    },
    getall: async (route: string) => {
        try {
            // Set no-cache header to prevent caching
            axios.defaults.headers.common['Cache-Control'] = 'no-cache';

            // Make the GET request
            const response = await axios.get(`${url}${route}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            
            // Extract data from the response
            return response.data;
        } catch (error: any) {
            // Handle errors
            if(localStorage.getItem('token')){
             //   localStorage.clear();
         //      window.location.replace("/admin/login");
            }
            
        }
    },
    post: async (route: string, data: any) => {
       // axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            const response = await axios.post(`${url}${route}`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'Access-Type-Key': 'Ld2TxLC4mziYedaiah2tUaRX3lXkQ=='
                }
            });
            return response.data;

        } catch (error: any) {
          //  localStorage.clear();
            return (error)
        }

    },
    upload: async (route: string, data: any) => {
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            const response = await axios.post(`${url}${route}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': localStorage.getItem("token")
                }
            });
            return response.data;

        } catch (error: any) {
            
            return (error)
        }

    },
    update: async (route: string, data: any) => {
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            const response = await axios.patch(`${url}${route}/${data.id}`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token")
                }
            });
            return response.data;

        } catch (error: any) {
            
            return (error)
        }
    },
    delete: async (route: string, id: any) => {
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            const response = await axios.delete(`${url}${route}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'Access-Type-Key': 'Ld2TxLC4mziYedaiah2tUaRX3lXkQ=='
                }
            });
            return response.data;

        } catch (error: any) {
            console.log(error)
            return(error)
        }
    },
    login: async (route: string, data: any) => {
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            await axios.post(`${url}${route}`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async (response:any)=>{
                const { access_token } = response.data
                if (access_token) {
                    localStorage.setItem('token', `Bearer ${access_token}`)
                    await Api.getall("getuser").then((data:any)=>{
                        localStorage.setItem('user',JSON.stringify(data))
                          
                      
                    })
                    
                    
                }
            })
            return "Success"

        }
        catch (e: any) {
            return e.response.data.message
        }

    },
    islogin: async()=>{
        try {
            // Set no-cache header to prevent caching
         //   axios.defaults.headers.common['Cache-Control'] = 'no-cache';

            // Make the GET request
            const response = await axios.get(`${url}getuser`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            
            // Extract data from the response
           // console.log(response.data) ;
        } catch (error: any) {
            // Handle errors
            if(localStorage.getItem('token')){
                localStorage.removeItem("token");
             //   console.log(error) ;
               window.location.replace("/admin/login");
            }
            
        }
    },
    isloginRef: async()=>{
        try {
            // Set no-cache header to prevent caching
         //   axios.defaults.headers.common['Cache-Control'] = 'no-cache';

            // Make the GET request
            const response = await axios.get(`${url}getuser`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            
            // Extract data from the response
           // console.log(response.data) ;
        } catch (error: any) {
            // Handle errors
            if(localStorage.getItem('token')){
                localStorage.removeItem("token");
             //   console.log(error) ;
            }
            
        }
    },
    reportApi: async (data:any)=>{
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        try {
            const response = await axios.post(`http://127.0.0.1:5000/send_message`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'Access-Type-Key': 'Ld2TxLC4mziYedaiah2tUaRX3lXkQ=='
                }
            });
            return response.data;

        } catch (error: any) {
          //  localStorage.clear();
            return (error)
        }
    }
}

export default Api;
