
import { useState } from "react"
import NotFound from "../../../Components/notFound"
import { useGetUser } from "../../../Hooks/useGetUser"
import image from "../../../assets/logo.png"
import Sidebar from "../../Admin/Sidebar"
import { Authentication } from "../../../Interface/Auth"
import Api from "../../../Api/api"

export default function Register() {
    const { user } = useGetUser()
    const [formData, setFormData] = useState<Authentication>()
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false)
    if (user?.role !== "Superadmin") {
        return (<div className="">
            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <NotFound />
            </div>
        </div>)

    }
    const handleSubmit = () => {
        if (formData?.password && formData.password.length < 8) {
          setErrorMessage('Password must be at least 8 characters long.');
        } else if (formData?.password !== formData?.confirmpassword) {
          setErrorMessage('Passwords do not match.');
        } else {
          setErrorMessage('');
          setLoading(true)
          Api.post("register",formData).then((response)=>{
            setErrorMessage(response.message)
            setLoading(false)
          })
        }
      };
      
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            <div className="lg:ml-72 ml-40">
                <section className="bg-gray-50 dark:bg-gray-900">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">


                        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">

                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <div className="mb-10">
                                    <img src={image} className="h-10 mx-auto" alt="la grande residence"></img>
                                </div>
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Create an account
                                </h1>
                                <form className="space-y-4 md:space-y-6"
                                onSubmit={(e)=>{
                                    e.preventDefault()
                                    handleSubmit()
                                }} >
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                            <input onChange={(e)=>{
                                                const {value} = e.target
                                                setFormData({...formData, email: value})
                                            }} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label>
                                            <input  onChange={(e)=>{
                                                const {value} = e.target
                                                setFormData({...formData, username: value})
                                            }}  type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="username" required />
                                        </div>
                                        <div>

                                            <label htmlFor="Role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a Role</label>
                                            <select  onChange={(e)=>{
                                                const {value} = e.target
                                                setFormData({...formData, role: value})
                                            }} id="Role" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option selected>Choose a Role</option>
                                                <option value="Superadmin">Superadmin</option>
                                                <option value="Admin">Admin</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Referral">Referral</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                            <input  onChange={(e)=>{
                                                const {value} = e.target
                                                setFormData({...formData, password: value})
                                            }} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        </div>
                                        <div>
                                            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                            <input  onChange={(e)=>{
                                                const {value} = e.target
                                                setFormData({...formData, confirmpassword: value})
                                            }}  type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        </div>
                                    </div>

                                  {loading ? <div className=" w-full text-center flex "><img className="h-5 mx-auto pulse-image" alt="la grande residence" src={image}></img></div>:<button type="submit" className="w-full text-white bg-lagrand-greenlight hover:bg-lagrand-green focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create an account</button>
                                  }  
                                </form>
                                {errorMessage && <p  className=" text-red-700 text-center text-sm">{errorMessage}</p>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}