'use client';
import Sidebar from "./Sidebar";
import { FcAcceptDatabase, FcAddDatabase, FcApproval, FcBookmark, FcCancel, FcCompactCamera, FcDataConfiguration, FcDeleteDatabase, FcDiploma2, FcNook, FcLandscape, FcOnlineSupport, FcPrint, FcServices } from "react-icons/fc";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotFound from "../../Components/notFound";
import { useEffect, useState } from "react";
import Api from "../../Api/api";
import { useGetUser } from "../../Hooks/useGetUser";

interface Report {
    action?: string,
    type?: string,
    item?: string,
    description?: string
}

export default function Report() {
    const { user } = useGetUser()
    const [formData, setFormData] = useState<Report>({})
    const [data, setData] = useState<string[]>([])
    const [update,setUpdate] = useState(true)
    const date = new Date
    const month = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const [copy, setCopy] = useState<string>('')
    useEffect(()=>{
        let toCopy = `${user?.username} | Accomplishments \n ${month[date.getMonth()]} ${date.getUTCDate()} ${date.getFullYear()}`
        const data_ = localStorage.getItem("report")?.split(",")
        if(data_){
            setData(data_)
            data_.forEach((item)=>{
                toCopy += `\n${item}`
            })
        }
       setCopy(toCopy)
    },[update])
    console.log(copy)
    const onsave = () => {
        const report = `\t• ${formData?.action} ${formData?.item} ${formData?.type} ${formData.description ? formData.description : ""}`
        const datalist =[...data, report]
        const toCopy = `${copy}\n${report}`
        setData([...datalist, report])
       // setCopy(toCopy)
        setFormData({ item: "", type: "", description: "" ,action: ""})
        
        localStorage.setItem("report",datalist.toString())
        localStorage.setItem("toCopyReport",toCopy)
        setUpdate(!update)
    }
    const sendData = () => {
        Api.reportApi({phone_number:"+639565575763",message:copy}).then((data:any)=>{
            console.log(data)
        })
    }
    const removeItem = (indexToRemove: number) => {
        localStorage.removeItem("report")
        const remove = data[indexToRemove]
        const toCopy = copy.replace(remove, "")
      //  setCopy(toCopy)
        const updatedList = data.filter((_, index) => index !== indexToRemove);
        
        // Update the state with the new list
        setData(updatedList);
        if(updatedList.length> 0){
            localStorage.setItem("report",updatedList.toString())
            localStorage.setItem("toCopyReport",toCopy)
        }
        setUpdate(!update)
        
    }
    const action = [
        {
            name: "Add",
            icon: FcAddDatabase,
            action: "Added"
        },
        {
            name: "Create",
            icon: FcAddDatabase,
            action: "Created"
        },
        {
            name: "Optimize",
            icon: FcServices,
            action: "Optimized"
        },
        {
            name: "Update",
            icon: FcAcceptDatabase,
            action: "Updated"
        },
        {
            name: "Remove",
            icon: FcDeleteDatabase,
            action: "Removed"
        },
        {
            name: "Fix",
            icon: FcDataConfiguration,
            action: "Fixed"
        },
        {
            name: "Print",
            icon: FcPrint,
            action: "Printed"
        },
        {
            name: "Photo",
            icon: FcCompactCamera,
            action: "Took Photo"
        },
        {
            name: "Assist",
            icon: FcOnlineSupport,
            action: "Assisted"
        },
       

    ]
    
    if (user?.role !== "Superadmin") {
        return (<div className="">
            <Sidebar></Sidebar>
            <div className="lg:ml-72 ml-40">
                <NotFound />
            </div>
        </div>)

    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            <div className="lg:ml-72 ml-40">
                <div className="flex h-screen place-items-center ">

                    <div className="mx-auto max-w-7xl rounded shadow-xl p-10">
                        <h1 className="text-xl mb-5 text-lagrand-greenlight text-center font-light">Report Managemanet</h1>
                        <div className="grid grid-cols-4 ">
                            {action.map((item) => (
                                <button onClick={() => { setFormData({ ...formData, action: item.action }) }} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                    <item.icon className="w-5 h-5 me-2" /> {item.name}
                                </button>
                            ))}
                            <input value={formData.action} onChange={(e: any) => {
                                const { value } = e.target
                                setFormData({ ...formData, action: value })
                            }} placeholder="other" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2" />

                        </div>
                        <hr></hr>
                        <div className="grid grid-cols-4 mt-5 ">
                            <input value={formData.item} onChange={(e: any) => {
                                const { value } = e.target
                                setFormData({ ...formData, item: value })
                            }} placeholder="Item" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2" />
                            </div>
                            <hr></hr>
                        <div className="grid grid-cols-4 mt-5 ">
                        <input value={formData.type} onChange={(e: any) => {
                                const { value } = e.target
                                setFormData({ ...formData, type: value })
                            }} placeholder="other" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2" />

                            <button onClick={() => {
                                setFormData({ ...formData, type: "Layout" })
                            }} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                <FcLandscape className="w-5 h-5 me-2" /> Layout
                            </button>
                            <button onClick={() => {
                                setFormData({ ...formData, type: "Sticker" })
                            }} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                <FcBookmark className="w-5 h-5 me-2" /> Sticker
                            </button>
                            <button onClick={() => {
                                setFormData({ ...formData, type: "Page" })
                            }} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                <FcDiploma2 className="w-5 h-5 me-2" /> Page
                            </button>
                            
                        </div>

                        <div className="grid grid-cols-4 mt-5 ">
                         <input value={formData.description} onChange={(e: any) => {
                                const { value } = e.target
                                setFormData({ ...formData, description: value })
                            }} placeholder="description" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2" />

                            <button onClick={onsave} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                <FcApproval className="w-5 h-5 me-2" /> Add Report
                            </button>
                            <button onClick={() => {
                                setData([])
                                localStorage.removeItem("report")
                            }} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                <FcCancel className="w-5 h-5 me-2" /> Clear all Reports
                            </button>


                        </div>
                        <h1 className="text-lg mb-5 mt-2 underline text-lagrand-greenlight text-center font-light">{formData?.action} {formData?.item} {formData?.type} {formData?.description}</h1>
                        <hr></hr>
                        <div className="mx-auto max-w-7xl p-10">
                            <h1 className="text-xl mb-5 text-lagrand-greenlight text-center font-light">Report Summary</h1>


                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[520px]">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  overflow-auto">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Report(s)
                                            </th>

                                            <th scope="col" className="px-6 py-3">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, key) => (
                                            <tr className="odd:bg-white odd:dark:bg-gray-900 text-sm even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {item}
                                                </th>

                                                <td className="px-6 py-4">
                                                    <button onClick={() => removeItem(key)} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                                        <FcCancel className="w-4 h-4 me-2" /> Delete Report
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}



                                    </tbody>
                                </table>

                            </div>
                            <div className="text-center">
                                <CopyToClipboard text={copy} >
                                    <button onClick={sendData}  type="button" className="text-gray-900 mt-5 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                        <FcNook className="w-5 h-5 me-2" />Copy to Clipboard
                                    </button>
                                </CopyToClipboard>
                            </div>


                        </div>


                    </div>

                </div>
            </div>

        </section>
    );
}