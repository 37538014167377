import { useState, useEffect } from "react"
import Api from "../Api/api"

export const useFetchAllData = (route: string)=>{
    const [data, setData] = useState<any>([])
    const [update, setUpdate] = useState<any>(true)
    useEffect(() => {
        Api.getall(route).then((data: any) => {
            setData(data)
        })
    }, [update])
    return{data,setUpdate,update}
}