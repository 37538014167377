import { useEffect, useState } from "react"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FiDelete } from "react-icons/fi";
import { Tooltip } from "flowbite-react";
import Api from "../../Api/api";
import { useGetUser } from "../../Hooks/useGetUser";
export default function SmtpAdmin() {
  const [expiration, setExpiration] = useState<any>(new Date())
  const [status, setStatus] = useState("Active")
  const [password, setPassword] = useState("*****")
  const {user} = useGetUser()
  const date = new Date()
  const [driver, setDriver] = useState<any>([])
  const [users, setUser] = useState<any>([])
  const [update,setUpdate]= useState(true)
  useEffect(() => {
    const date = new Date()
    const dateOptimized = localStorage.getItem("date")
    Api.getall('healthcheck/smtp').then((data: any) => {
      const expiration = new Date(data.expiry)
      setExpiration(expiration)
      if (expiration <= date) {
        
        setStatus("Expired")
        Api.getall("enable/smtp")
      }
      else {
        setStatus("Active")
      }
    })
    Api.getall("driver").then((data: any) => {
      setDriver(data)
    })
    Api.getall("users").then((data: any) => {
      setUser(data)
    })
    const handleOptimize = async ()=>{
      const response = await Api.getall("image")
      const {data} = response
      data.forEach(async (element:any) => {
        const response = await Api.delete('images',element)
        console.log(response)
        localStorage.setItem("date",`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`)
      });
    }
    if(dateOptimized){
      if (!isYesterday(dateOptimized)){
        handleOptimize()
      }
    }
    else{
      handleOptimize()
    }
    
  }, [update])
  const deleteData = (id:any,route:any)=>{
    Api.delete(route,id).then(()=>{
      setUpdate(!update)
    })
  }
  const isYesterday = (date:any) => {
    const today = new Date();
    const yesterday = new Date(date);
  //  yesterday.setDate(yesterday.getDate() - 1);
  
    const inputDate = new Date(date);
  
    // Check if the input date is equal to yesterday's date
    return inputDate.getDate() === yesterday.getDate() &&
           inputDate.getMonth() === yesterday.getMonth() &&
           inputDate.getFullYear() === yesterday.getFullYear();
  };
  return (
    <section className="p-20">
      <div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-3">


      </div>
      <div className="grid grid-cols-1 px-4 pt-6 md:grid-cols-3 xl:gap-4 ">

        <div className="col-span-full xl:col-auto">
               
          <div className="hidden md:flex p-4 mb-4 bg-white  rounded-lg  2xl:col-span-2 ">
            <div>
              <h3 className="mb-4  text-xl  font-semibold text-lagrand-green">Calendar</h3>
              <div className="w-full">
                <Calendar className="shadow-xl rounded text-lagrand-greenlight"

                  value={date}
                />
              </div>
                  
            </div>
          </div>

          <div className={`p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm ${user?.role !== "Superadmin" && "hidden"} `}>
            <div className="w-full">
              <h3 className="text-base font-normal text-gray-500 ">SMTP Status (<a className="underline text-lagrand-greenlight" href="https://www.pythonanywhere.com/user/lagrande/webapps/#tab_id_lagrande_pythonanywhere_com" target="_blank" rel="noreferrer">Python Anywhere</a>)</h3>
              <span className={`text-2xl font-bold leading-none  sm:text-3xl  ${status === "Active" ? " text-lagrand-greenlight" : " text-red-700"}`}>{status}</span>
              <div className="flex"><p className=" text-sm items-center font-normal text-gray-500 ">
                Expiration:
              </p>
                <p className=" ms-2 text-sm items-center font-normal text-gray-500 ">
                  {expiration.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                </p>
              </div>
              <div className="flex"> <p className=" text-sm items-center font-normal text-gray-500 ">
                Username:
              </p>
                <p className=" ms-2 text-sm items-center font-normal text-gray-500 ">
                  lagrande
                </p>
                <p className="ms-2 text-sm items-center font-normal text-gray-500 ">
                  Password:
                </p>
                <p onClick={() => setPassword("lgr2024*****!")} className=" ms-2 text-sm items-center font-normal text-gray-500  cursor-pointer">
                  {password}
                </p>
              </div>
            </div>

          </div>
          <div className={`p-4 mb-4 bg-white  border border-gray-200 rounded-lg shadow-sm ${user?.role === "Superadmin" && "hidden"} `}>
            <div className="flow-root">
              <h3 className="text-xl font-semibold text-lagrand-greenlight">My Account</h3>
              <ul className="mb-6 divide-y divide-gray-200 ">
                <li className="py-4">
                  <div className="flex justify-between xl:block 2xl:flex align-center 2xl:space-x-4">
                    <div className="flex space-x-4 xl:mb-4 2xl:mb-0">
                      <div>
                        {/* <img className="w-6 h-6 rounded-full" src="" alt={user?.username}/>                             */}
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-base font-semibold text-lagrand-greenlight leading-none truncate mb-0.5 ">
                          {user?.username}
                        </p>

                        <p className="text-xs font-medium text-lagrand-greenlight ">
                          {user?.role}
                        </p>
                      </div>
                    </div>
                    <div className="inline-flex items-center w-auto xl:w-full 2xl:w-auto">
                      <a onClick={() => {}} href="/admin/login" className="w-full px-3 py-2 text-sm font-medium text-center text-lagrand-greenlight bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300">Logout</a>
                       
                    </div>
                    
                  </div>
                </li>

              </ul>
              <div>
                <button className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center -600 primary-700 -primary-800">Save all</button>
              </div>
            </div>
          </div>


        </div>
        
        <div className="col-span-2">


        <div className={`p-4 mb-4 bg-white  border border-gray-200 rounded-lg shadow-sm ${user?.role !== "Superadmin" && "hidden"} `}>
            <div className="flow-root">
              <h3 className="text-xl font-semibold text-lagrand-greenlight">My Account</h3>
              <ul className="mb-6 divide-y divide-gray-200 ">
                <li className="py-4">
                  <div className="flex justify-between xl:block 2xl:flex align-center 2xl:space-x-4">
                    <div className="flex space-x-4 xl:mb-4 2xl:mb-0">
                      <div>
                        {/* <img className="w-6 h-6 rounded-full" src="" alt={user?.username}/>                             */}
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-base font-semibold text-lagrand-greenlight leading-none truncate mb-0.5 ">
                          {user?.username}
                        </p>

                        <p className="text-xs font-medium text-lagrand-greenlight ">
                          {user?.role}
                        </p>
                      </div>
                    </div>
                    <div className="inline-flex items-center w-auto xl:w-full 2xl:w-auto">
                      <a onClick={() => localStorage.clear()} href="/admin/login" className="w-full px-3 py-2 text-sm font-medium text-center text-lagrand-greenlight bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300">Logout</a>
                    </div>
                  </div>
                </li>

              </ul>
              <div>
                <button className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center -600 rimary-700 -primary-800">Save all</button>
              </div>
            </div>
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm y-700 sm:p-6 0">
            <div className="items-center justify-between lg:flex">
              <div className="mb-4 lg:mb-0">
                <h3 className="mb-2 text-xl font-bold text-lagrand-greenlight ">User List</h3>
                <span className="text-base font-normal text-gray-500 "></span>
              </div>


            </div>
          </div>
          <div className="flex flex-col mt-6">
            <div className="overflow-auto rounded-lg">
              <div className="inline-block min-w-full max-h-[500px] align-middle">
                <div className="overflow-hidden shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 y-600">
                    <thead className="bg-gray-50 0">
                      <tr>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Username
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Email
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Role
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white 0">
                      {users?.map((item:any) => (
                        <tr>
                          <td className="p-4 text-sm font-normal text-lagrand-greenlight whitespace-nowrap ">
                          <span className="font-semibold flex ">{item.username}</span>
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap ">
                            {item.email}
                          </td>
                          <td className="p-4 text-sm font-semibold text-lagrand-greenlight whitespace-nowrap ">
                            {item.role}
                          </td>
                          <td className="p-4 text-sm font-semibold text-lagrand-greenlight whitespace-nowrap ">
                          <Tooltip content="Delete Account"><button onClick={()=>{deleteData(item.id,"users")}}><FiDelete className="h-5 w-5 ms-2 text-red-700" /></button></Tooltip>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm y-700 sm:p-6 0">
            <div className="items-center justify-between lg:flex">
              <div className="mb-4 lg:mb-0">
                <h3 className="mb-2 text-xl font-bold text-lagrand-greenlight ">Driver List</h3>
                <span className="text-base font-normal text-gray-500 ">This is a list of driver accounts in the driver's portal.</span>
              </div>


            </div>
          </div>
          <div className="flex flex-col mt-6">
            <div className="overflow-auto rounded-lg">
              <div className="inline-block min-w-full max-h-[500px] align-middle">
                <div className="overflow-hidden shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 y-600">
                    <thead className="bg-gray-50 0">
                      <tr>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Name
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Email
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Type
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white 0">
                      {driver?.map((item:any) => (
                        <tr>
                          <td className="p-4 text-sm font-normal text-lagrand-greenlight whitespace-nowrap ">
                          <span className="font-semibold flex ">{item.name}</span>
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap ">
                            {item.email}
                          </td>
                          <td className="p-4 text-sm font-semibold text-lagrand-greenlight whitespace-nowrap ">
                            {item.type}
                          </td>
                          <td className="p-4 text-sm font-semibold text-lagrand-greenlight whitespace-nowrap ">
                          <Tooltip content="Delete Account"><button onClick={()=>{deleteData(item.id,"driver")}}><FiDelete className="h-5 w-5 ms-2 text-red-700" /></button></Tooltip>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
       
        </div>


      </div>
    </section>
  )
}