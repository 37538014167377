import { useState, useEffect } from "react";

export const useBannerAnimation=(interval:number)=>{
    const [isVisible, setIsVisible] = useState(true);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsVisible(prevState => !prevState); // Toggle visibility
        }, interval); // Trigger every 5 seconds

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return{ isVisible }
}