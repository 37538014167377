import { Headline } from "../Interface/PageHeadline";

export default function Title (props:Headline) {
    const {Heading, SubHeading} = props
    return(
        <div className="pt-20 text-center revealBottom5">
            <h1 className="mb-4 text-3xl font-thin  tracking-tight text-lagrand-greenlight leading-none max-w-4xl md:px-48 xl:px-[500px]  md:max-w-full xl:max-w-full px-10 md:text-5xl fade">{Heading}</h1>
            <p className="md:mb-20 mb-5 text-sm font-light text-gray-700 md:text-sm xl:px-[500px]   xl:text-lg md:max-w-4xl xl:max-w-full px-10 md:px-48 fade">{SubHeading}
            </p>
            </div>
    )
}