'use client';

import {Modal } from "flowbite-react";
import { FaTrashAlt } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { IoIosJournal } from "react-icons/io";
import SpinnerLoading from "../../Components/Spinner";
import NotFound from "../../Components/notFound";
import { useGetUser } from "../../Hooks/useGetUser";
import { usePromo } from "../../Hooks/usePromo";
import PageBanner from "../../Components/PageBanner";
import Title from "../../Components/Title";
import Sidebar from "./Sidebar";


export default function PromoAdmin() {
    const {user} = useGetUser()
    const {sortedData,openModal,setOpenModal,editItem,handleChange,handleDelete,handleSave,file,error,item,setItem} = usePromo()
    if( user?.role !== "Superadmin" && user?.role !== "Marketing"){
        return ( <div className="">
                <Sidebar></Sidebar>
                <div className="lg:ml-72 ml-40">
                <NotFound/>
                </div>
            </div>)
       
    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
             {!sortedData && <SpinnerLoading></SpinnerLoading>}
            <div className="lg:ml-72 ml-40">
                <section className="bg-center bg-fixed text-lagrand-white md:bg-cover journal bg-gray-400 bg-blend-multiply">
                   <PageBanner Heading="Promos & Packages" SubHeading=""/>
                    <div className="bg-lagrand-white pt-10 pb-20 revealBottom">
                        <Title Heading="Latest Promo" SubHeading=""/>
                        <div className="md:px-[200px] xl:px-[500px]">
                        <button onClick={()=>{editItem(null)}} className="text-gray-900 bg-lagrand-white hover:bg-gray-200 shadow-sm border focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  me-2 mb-2">
                        <FcFile className="w-5 h-5 mr-2" />  Upload New Promo
                        </button>
                        </div>
                        
                        <div className="revealBottom2">
                            <div className="md:grid grid-cols-3 gap-4 px-10 md:px-[200px] xl:px-[500px]">
                                {sortedData?.map((item) => (
                                    <div key={item.name} onClick={() => { editItem(item) }} rel="noopener noreferrer">
                                        <img src={item.image_url} alt={item.name} loading="lazy" className="transform transition duration-300 hover:scale-105 shadow-xl inset-0 w-full h-[90%] object-cover left-0 object-left cursor-pointer"></img>
                                        <h2 className="text-xl font-normal text-center mx-auto pt-4 text-gray-900 md:text-xl">{item.name}</h2>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>{item?.name ? item.name:"New Promo"}</Modal.Header>
                <Modal.Body>
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Promo Name</label>
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <IoIosJournal className="w-4 h-4" />
                        </div>
                        <input type="text" id="input-group-1" value={item?.name} onChange={(e) => {
                            const { value } = e.target
                            setItem({ ...item, name: value })
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Promo name"/>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white" htmlFor="file_input">Update Picture</label>
                        <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" accept=".jpg,.jpeg,.png" onChange={handleChange} />
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help"> PNG or JPG (2 mb max).</p>
                        {error && <div className="text-sm mt-2 text-red-700">{error}</div>}
                        {file && <div className="text-sm mt-2 text-lagrand-green">Selected file: {file?.name}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="focus:outline-none text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => handleSave()}>Save</button>
                    {item?.id && <button className="focus:outline-none text-lagrand-white bg-red-700 hover:bg-red-800 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => handleDelete()}><FaTrashAlt  className="w-5 h-5" /></button>}
                </Modal.Footer>
            </Modal>
        </section>





    );
    
}