import { useState } from "react";
import image from "../../../assets/logo.png"
import Api from "../../../Api/api";
export default function DriverLogin (){
    const [email, setEmail] = useState('');
    const handleSignIn = () => {
        Api.post("dlogin",{email:email}).then((data:any)=>{
            if(data.type === "Driver"){
                localStorage.setItem("driver",JSON.stringify(data))
                sessionStorage.setItem('page','Free Shuttle')
                window.location.reload()
            }
            else {
                alert("Invalid email")
            }
             
            
           
           
        })
    }
    return (
        <section>
            <div className="">
        <div className="mx-auto max-w-xl h-screen lg:flex place-items-center ">
            <div className="lg:p-20  pt-20 lg:bg-white lg:rounded-xl lg:shadow-xl  ">
                <form className="max-w-sm mx-10 lg:mx-auto" onSubmit={(e) => {
                    e.preventDefault();
                    handleSignIn();
                }}>
                    <div className="mb-5">
                        <img src={image} className="h-10 mx-auto" alt="La Grande Residence" ></img>
                    </div>
                    <div className="mb-5 lg:w-80">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Driver's Portal</label>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  " placeholder="@email.com" required />
                    </div>

                    <button type="submit" className="text-white bg-[#588a3c] hover:bg-[#416a35] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center">Login</button>
                </form>

            </div>
        </div>
        </div>
        </section>
    )
}