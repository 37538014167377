import { useState, useEffect } from "react"
import Api from "../Api/api"

export const useFetchDataPaginate = (route: string) => {
    const [update, setUpdate] = useState<any>(true)
    const [data, setData] = useState<any>([])
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1)
    useEffect(() => {
        setPage(1)
        Api.getall(route).then((data: any) => {
            const { total_pages } = data
            setData(data.data)
            setTotalPage(total_pages)

        })
    }, [update])
    const appendData = () => {

        Api.getall(`${route}?page=${page + 1}`).then((data: any) => {
            setData((prevDataList: any) => [...prevDataList, ...data.data]);
            setPage(page + 1)

        })
    }
    return { data, setData, update, setUpdate, totalPage, appendData, setTotalPage, page }
}