import { useEffect, useState } from "react";
import Api from "../../../Api/api";
import time_convert from "../../../Functions/time_convert";
import PageBanner from "../../../Components/PageBanner";
export default function MaintenancePortal() {
    const [formdata, setFormdata] = useState<any>({})
    const [other, setOther] = useState(false)
    const maintenancestring = localStorage.getItem("maintenance");
    const [done, setDone] = useState<any>([])
    const status = ["INFORMED","BILLED","PENDING", "DONE","ON-GOING","SCHEDULED","PENDING SCHEDULE","PENDING MATERIAL","PENDING APPROVAL","APPROVED","APPROVED FOR SCHE","PENDING QOUTATION"]
    let maintenance: any; // Or specify the type of your maintenance object
    const id = "1z18m2pUtM38LFHQ0De5347d3qsZd5FWBxm4ViQDB9X0"
    useEffect(() => {
        const session = sessionStorage.getItem("maintenanceform")
        if (session) {
            setFormdata(JSON.parse(session))
        }
        const range = "Attended Staff"
        Api.post("spreadsheet/all", {
            "id": id,
            "range": `${range}!A:A`,

        }).then((response) => {
            const { data } = response
            setDone(data)
        })
    }, [])
    console.log(done)
    useEffect(() => {
        sessionStorage.setItem("maintenanceform", JSON.stringify(formdata))
        console.log(formdata)
    }, [formdata])
    if (maintenancestring !== null) {
        maintenance = JSON.parse(maintenancestring);
    } else {
        // Handle the case where the data is not found in localStorage
        console.error("Driver data not found in localStorage");
    }
    const onsave = () => {
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const time = currentDate.getHours() + ':' + currentDate.getMinutes()
        const formattedDateTime = `${month}/${day}/${year} ${time_convert.time(time)}`;
        let value: any[] = []
        let range = "Repair and Maintenance 2024";

        value = [formattedDateTime, '', formdata.unit, formdata.date, formdata.name, formdata.urgency, formdata.work, formdata.remarks,formdata.status,formdata.done]


        Api.post("spreadsheetRpr", {
            "id": id,
            "range": `${range}!A:A`,
            "values": [
                value
            ]
        }).then((data: any) => {

            if (data.message === "Data added successfully.") {

                sessionStorage.clear()
            }
            console.log(data)
            alert(data.message)
            window.location.reload()
        })


    };
    // if (!maintenance) {
    //     return <MaintenanceLogin />
    // }
    return (
        <section className={`bg-center bg-fixed text-lagrand-white  md:bg-cover landing bg-gray-400 bg-blend-multiply`}>
            <PageBanner Heading="Maintenance Portal" SubHeading="" />

            <div className="lg:px-[200px] bg-lagrand-white h-screen pt-5 md:px-[100px] xl:px-[500px] px-5">


                <form onSubmit={(e) => {
                    e.preventDefault();
                    onsave();
                }} >
                    <h1 className="text-3xl font-thin mb-5 text-black text-center">Maintenance Portal</h1>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        {/* <div className="col-span-2">
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Name : {maintenance.name} <p onClick={
                                () => {
                                    localStorage.clear()
                                    window.location.reload()
                                }
                            } className="text-red-600 underline">Logout</p></label>
                        </div> */}
                        <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Unit No.</label>
                            <input value={formdata.unit} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, unit: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Unit #" />
                        </div>
                        <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Name</label>
                            <input value={formdata.name} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, name: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name" />
                        </div>

                        <div>
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Date Prepared</label>
                            <input value={formdata.date} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, date: value }); // If value is undefined, set it to an empty string
                            }} type="date" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                        </div>
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Urgency</label>
                            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {

                                    const { value } = e.target;
                                    setFormdata({ ...formdata, urgency: value });
                                    // If value is undefined, set it to an empty string
                                }}
                                required>
                                <option selected>{formdata.urgency ? formdata.urgency : 'Choose a type of Urgency'}</option>
                                <option value="Normal">Normal</option>
                                <option value="High">High</option>
                                <option value="Emergency">Emergency</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Work</label>
                            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {

                                    const { value } = e.target;
                                    if (value === "Other") {
                                        setOther(true)
                                    }
                                    else {
                                        setOther(false)
                                        setFormdata({ ...formdata, work: value });
                                    }

                                    // If value is undefined, set it to an empty string
                                }}
                                required >
                                <option selected>{formdata.work ? formdata.work : 'Choose a type of Work'}</option>
                                <option value="Aircon Concern">Aircon Concern</option>
                                <option value="Meter Concern">Meter Concern</option>
                                <option value="Maintenance Concern">Maintenance Concern</option>
                                <option value="Sunday Duty Concern">Sunday Duty Concern</option>
                                <option value="Furniture Concern">Furniture Concern</option>
                                <option value="Wall Repair & Repaint">Wall Repair & Repaint</option>
                                <option value="Tile Repair or Replacement">Tile Repair or Replacement</option>
                                <option value="Battery Replacement">Battery Replacement</option>
                                <option value="Fixture Replacement">Fixture Replacement</option>
                                <option value="Appliance Replacement">Appliance Replacement</option>
                                <option value="Light Replacement">Light Replacement</option>
                                <option value="Lockset Replacement">Lockset Replacement</option>
                                <option value="Item Replacement">Item Replacement</option>
                                <option value="Electrical Concern">Electrical Concern</option>
                                <option value="Pipe Concern">Pipe Concern</option>
                                <option value="Room Assessment">Room Assessment</option>
                                <option value="General Assistance">General Assistance</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Scope of Work</label>
                            <input value={formdata.remarks} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, remarks: value }); // If value is undefined, set it to an empty string
                            }} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="remarks..." />
                        </div>
                        {other && <div>
                            <label htmlFor="visitors" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Type of Work</label>
                            <textarea value={formdata.work} onChange={(e) => {
                                const { value } = e.target;
                                setFormdata({ ...formdata, work: value });// If value is undefined, set it to an empty string
                            }} id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Other..." />
                        </div>}
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Work Status</label>
                            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {

                                    const { value } = e.target;
                 
                                    setFormdata({ ...formdata, status: value });

                                    // If value is undefined, set it to an empty string
                                }}
                                required >
                                <option selected>{formdata.status ? formdata.status : 'Choose a type of Work'}</option>
                                {status.map((item:any)=>(
                                    <option value={item}>{item}</option>
                                ))}
                                
                            </select>
                        </div>
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-lagrand-white">Done By</label>
                            <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-lagrand-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => {

                                    const { value } = e.target;
                             
                                    setFormdata({ ...formdata, done: value });

                                    // If value is undefined, set it to an empty string
                                }}
                                required >
                                <option selected>{formdata.done ? formdata.done : 'Choose a type of Work'}</option>
                                {done.map((item:any)=>(
                                    <option value={item?.Employee}>{item?.Employee}</option>
                                ))}
                                
                            </select>
                        </div>
                    </div>



                    <button type="submit" className="text-lagrand-white bg-lagrand-green hover:bg-[#2c4824] mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    <button type="button" onClick={() => {
                        sessionStorage.removeItem("maintenanceform")
                        setFormdata({})
                        window.location.reload()
                    }} className="text-lagrand-white ml-2 bg-red-600 hover:bg-red-700 mb-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</button>
                </form>
            </div>
        </section>
    )
}