'use client';
import {  Modal } from "flowbite-react";
import { BsPersonCircle } from "react-icons/bs";
import { FaTrashAlt } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { IoIosJournal } from "react-icons/io";
import { SlArrowDownCircle } from "react-icons/sl";
import SpinnerLoading from "../../Components/Spinner";
import NotFound from "../../Components/notFound";
import { truncateText } from "../../Functions/truncate";
import { useGetUser } from "../../Hooks/useGetUser";
import PageBanner from "../../Components/PageBanner";
import { useBlog } from "../../Hooks/useBlog";
import Sidebar from "./Sidebar";
;


export default function BlogAdmin() {
    const {user} = useGetUser()
    const {data,appendData,page,totalPage,handleChange,handleDelete,handleSave,editItem,file,error,setItem,item,setOpenModal,openModal} = useBlog()
      if( user?.role !== "Superadmin" && user?.role !== "Marketing"){
        return ( <div className="">
                <Sidebar></Sidebar>
                <div className="lg:ml-72 ml-40">
                <NotFound/>
                </div>
            </div>)
       
    }
    return (
        <section>
            <div className="">
                <Sidebar></Sidebar>
            </div>
            {!data && <SpinnerLoading></SpinnerLoading>}
            <div className="lg:ml-72 ml-40">
                <section className="bg-center bg-fixed text-white md:bg-cover journal bg-gray-400 bg-blend-multiply">
               
                    <PageBanner Heading="Blog" SubHeading="Exploring the Latest Trends in La Grande Residence." />
                    <div className="bg-white pt-10 pb-20 revealBottom">
                        <div className="revealBottom5">


                        </div>
                        <div className="md:px-[200px] xl:px-[500px]">
                            <button onClick={() => { editItem(null) }} className="text-gray-900 bg-white hover:bg-gray-200 shadow-sm border focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  me-2 mb-2">
                                <FcFile className="w-5 h-5 mr-2" />  Upload New Blog
                            </button>
                        </div>

                        <div className="revealBottom2">
                            <div className="px-10 md:px-[200px] xl:px-[500px]">
                                {data?.map((item: any) => (
                                    <div key={item.title} onClick={() => { editItem(item) }} className="border shadow-lg rounded-md grid grid-cols-3 mb-5 overflow-hidden max-h-96 relative">
                                        <img src={item.image_url} alt={item.title} loading="lazy" className=""></img>
                                        <div className="h-[100%] col-span-2 ">

                                            <div className="ms-5 mt-5 text-sm font-normal text-gray-700 md:max-w-2xl md:text-2xl flex ">
                                                <BsPersonCircle className="h-8 w-8 mr-4 text-gray-600" />
                                                <div>
                                                    <p className="font-normal text-xs tracking-tighter">La Grande Residence</p>
                                                    <p className="text-gray-500 tracking-tighter text-xs">
                                                    {new Date(new Date(item.created_at).getTime() + (8 * 60 * 60 * 1000)).toLocaleDateString('en-US', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}
                                                    </p>
                                                </div>

                                            </div>
                                            <hr className="mt-5 mx-5" />
                                            <h2 className="text-sm font-extralight mx-5 pt-4  tracking-tight text-gray-900 md:text-2xl">{item.title}</h2>
                                            <h2 className=" text-xs font-normal   text-justify tracking-wide  pt-5 mb-10 text-gray-600 md:text-sm mx-5">{truncateText(item.body)}</h2>

                                        </div>

                                    </div>


                                ))}
                                <div className="w-full text-center">
                                    {totalPage !== page ?<button type="button" className="mt-5" onClick={appendData}>
                                        <SlArrowDownCircle className="h-12 w-12 text-[#416a35]  animate-bounce" />
                                    </button> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>{item?.title ? item.title : "New blog"}</Modal.Header>
                <Modal.Body>
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Blog Title</label>
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <IoIosJournal className="w-4 h-4" />
                        </div>
                        <input type="text" id="input-group-1" value={item?.title} onChange={(e) => {
                            const { value } = e.target
                            setItem({ ...item, title: value })
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  " placeholder="Blog Title" />
                    </div>
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Blog Body</label>
                    <div className="relative mb-6">

                        <textarea value={item?.body} onChange={(e) => {
                            const { value } = e.target
                            setItem({ ...item, body: value })
                        }} className="bg-gray-50 border h-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  " placeholder="Body" />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Update Picture</label>
                        <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none " type="file" accept=".jpg,.jpeg,.png" onChange={handleChange} />
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help"> PNG or JPG (2 mb max).</p>
                        {error && <div className="text-sm mt-2 text-red-700">{error}</div>}
                        {file && <div className="text-sm mt-2 text-[#416a35]">Selected file: {file?.name}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="focus:outline-none text-white bg-[#416a35] hover:bg-[#2c4824] focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => handleSave()}>Save</button>
                    {item?.id && <button className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => handleDelete()}><FaTrashAlt className="w-5 h-5" /></button>}
                </Modal.Footer>
            </Modal>
        </section>





    );
}