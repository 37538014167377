import { Outlet } from "react-router-dom";
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { HiOutlineBars3 } from "react-icons/hi2";
import logo from "../../../assets/logo.png"
import { FaXmark } from "react-icons/fa6";
import { NavDriver } from "../../../Utility/Objects/Driver";

export default function DriverNavbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const isSticky = true;

    const handleClick = (name:any) => {
        sessionStorage.setItem('page',name)
        window.location.reload()
    };


    return (
        <>
            <header className={`w-full transition-all duration-400 ease-in-out  top-0 z-50`}>
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8" aria-label="Global">
                    <div className="flex md:flex-1">
                        <button onClick={() =>{handleClick('Free Shuttle')}} className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img className="h-10 w-auto drop-shadow-lg" src={logo} alt="" loading="lazy" /> {/* Add lazy loading here */}
                        </button>
                    </div>
                    <div className={`flex md:hidden ${mobileMenuOpen ? "hidden" : ""}`}>
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 "
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <HiOutlineBars3 className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="hidden md:flex md:gap-x-7">
                        {NavDriver.map((item, key) => (
                            <button  onClick={() =>{handleClick(item.name)}} className={`hover:underline py-2 text-sm gap-x-1 leading-10 font-normal ${isSticky ? 'text-black hover:text-black' : 'text-lagrand-white hover:text-lagrand-white text-shadow'}`}>
                                <p className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">{item.name}</p>
                            </button>
                        ))}
                    </div>
                </nav>
                <Dialog as="div" className="md:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed pt-10 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-lagrand-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <div className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    className="h-8 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </div>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <FaXmark className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className=" flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {NavDriver.map((item) => (
                                        <div className="-mx-3" key={item.name}>
                                            <button onClick={() => { setMobileMenuOpen(false); handleClick(item.name) }} className="flex mt-5 hover:text-black hover:underline w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                {item.name}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            <Outlet />
        </>
    )
}
